// import soon1 from './images/ribbons-and-bows/coming-soon5.png';
import logo1 from './images/heraldry/drenwal-crest-sm-opt.png';
import gear1 from './images/mechanical/gear06.svg';
import gear2 from './images/mechanical/gear02-w.svg';
import gear3 from './images/mechanical/gear03-bk.svg';
import comp1 from './images/heraldry/DrenwalCompass.svg';
import hand1 from './images/mechanical/clockHand-second.svg';
import hand2 from './images/mechanical/clockHand-minute.svg';
import hand3 from './images/mechanical/clockHand-hour.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">        
        {/* <img src={soon1} className="coming-soon dead-pointer" alt="logo" /> */}
        <img src={logo1} className="logo1 dead-pointer stay-boy" alt="the Drenwal shield crest with a crown above a crossed axe, sword, and wrench" />
        <img src={comp1} className="comp1 dead-pointer stay-boy" alt="an ornate compass behind the shield" />
        <img src={hand1} className="hand1 dead-pointer spin-0 stay-boy" alt="the second hand of a clock, spinning rapidly clockwise" />
        <img src={gear1} className="gear1 dead-pointer spin-3 stay-boy" alt="a solid black cog spinning clockwise" />
        <img src={hand2} className="hand2 dead-pointer spin-1 stay-boy" alt="the minute hand of a clock, spinning slowly clockwise" />
        <img src={hand3} className="hand3 dead-pointer spin-2 stay-boy" alt="the hour hand of a clock, spinning slowly counter-clockwise" />
        <img src={gear2} className="gear2 dead-pointer spin-4 stay-boy" alt="a white cog spinning slowly counter-clockwise" />
        <img src={gear3} className="gear3 dead-pointer spin-5 stay-boy" alt="a black cog spinning slowly clockwise" />
      </header>
    </div>
  );
}

export default App;
